<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable" v-loading="loading">
					<!-- <div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
										<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
									</el-tooltip>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div> -->

					<div>
						<div class="filterCon" style="">
							<!-- <el-row type="flex" justify="end"> -->
								<!-- <el-col :span="24"> -->
									<!-- <ul class="filterConList">
										<li>
											<span><i class="red">*</i>{{ $t('hytxs0000060') }}</span>
											<cusSelFuzzy ref="cusSelFuzzy" @changeData="changCus"></cusSelFuzzy>
										</li>
						
										<li>
											<span><i class="red">*</i>{{ $t('c944a6686d996ab3') }}</span>
											<whNoSelect @changeData="changWhNo"></whNoSelect>
										</li>
						
										<li>
											<span><i class="red">*</i>{{$t('i18nn_d99d790ec4383bfb')}}</span>
											<el-date-picker
												v-model="filterData.daterange"
												size="small"
												type="daterange"
												align="right"
												:clearable="true"
												unlink-panels
												range-separator="-"
												start-placeholder="start date"
												end-placeholder="end date"
												:picker-options="pickerOptions"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												@change="initData()"
											></el-date-picker>
										</li>
									</ul> -->
									<ul class="filterConList">
										
										<li>
											<span>{{$t('i18nn_45eae388d108d4fe')}}</span>
											<el-select filterable clearable v-model="filterData.wh_op_status" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
												<el-option v-for="item in selectOption.wh_op_status" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
													<div class="sel_option_s1" style="">
														<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
														<span class="sel_option_code" style="">{{ item.code }}</span>
													</div>
												</el-option>
											</el-select>
										</li>
										<li>
											<span>{{$t('i18nn_82b5bd967d494be3')}}</span>
											<el-select filterable clearable v-model="filterData.wh_op_type" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
												<el-option v-for="item in selectOption.wh_op_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
													<div class="sel_option_s1" style="">
														<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
														<span class="sel_option_code" style="">{{ item.code }}</span>
													</div>
												</el-option>
											</el-select>
										</li>
										<li>
											<span>{{$t('i18nn_06dd7cb65641390b')}}</span>
											<el-select filterable clearable v-model="filterData.wh_fin_bill_status" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
												<el-option v-for="item in selectOption.wh_fin_bill_status" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
													<div class="sel_option_s1" style="">
														<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
														<span class="sel_option_code" style="">{{ item.code }}</span>
													</div>
												</el-option>
											</el-select>
										</li>
										<!-- <li>
											<span>isBill</span>
											<el-select filterable clearable v-model="filterData.wh_op_is_bill" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
												<el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
											</el-select>
										</li> -->
										<li>
											<span>{{$t('i18nn_9168144190f66f5d')}}</span>
											<el-input
												type="text"
												v-model="filterData.relationNo"
												size="small"
												clearable
												@keyup.enter.native="initData()"
												maxlength="50"
												:placeholder="$t('hytxs0000001')"
												style="width: 180px;"
											/>
										</li>
										<li>
											<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
										</li>
									</ul>
								<!-- </el-col> -->
							<!-- </el-row> -->
						</div>
					</div>
					
					<div style="display:flex;align-items: center;">
						<div style="padding: 10px;">
							<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
								<el-button type="warning" icon="el-icon-download" size="mini" @click="exportExcel()">{{$t('i18nn_ff6fd823ffab7e6b')}}</el-button>
							</el-tooltip>
						</div>
						<el-descriptions title="" border style="width: 800px;">
							
						  <el-descriptions-item :label="$t('i18nn_6e9c4e33131b18a7')">{{ countData.recordCount }}</el-descriptions-item>
						  <el-descriptions-item :label="$t('i18nn_da35456de5403862')">{{ countData.totalFeeAmt }}</el-descriptions-item>
						</el-descriptions>
					</div>
					

					<el-table
						id="out-table2"
						ref="multipleTable"
						:data="tableData"
						stripe
						:border="true"
						:height="$store.state.tableMaxHeight9"
						@row-click="handleCurrentChange"
						@selection-change="handleSelectionChange"
						style="width: 100%"
						size="small"
					>
						<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" :index="indexMethod" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column> -->
						<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
						
						<el-table-column type="expand" fixed="left" width="50">
							<template slot-scope="scope">
								<div style="margin-left: 100px;">
									<div class="table-inline-text" v-if="scope.row.calcJsonObj">
										<ul>
											<li v-for="(item,index) in formatCalcData(scope.row.calcJsonObj,scope.row.feeType)" :key="index">
												<span>{{item.name}}</span>
												:
												<strong>{{ item.value }}</strong>
											</li>
										</ul>
									</div>
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="plStatusName" :label="$t('6cdece641436d7ab')"></el-table-column> -->

						<!-- <el-table-column prop="opNo" :label="$t('i18nn_4b5536ea9955714b')">
							<template slot-scope="scope">
								<span>{{ scope.row.opNo }}</span>
							</template>
						</el-table-column> -->
						<el-table-column prop="whNo" :label="$t('5acbec83efb27445')"></el-table-column>
						<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column> -->
						
						<el-table-column prop="relationNo" :label="$t('i18nn_9168144190f66f5d')"></el-table-column>

						<el-table-column prop="opStatus" :label="$t('i18nn_45eae388d108d4fe')">
							<template slot-scope="scope">
								<div>
									<el-tag v-if="'10' == scope.row.opStatus">{{ scope.row.opStatusName }}</el-tag>
									<el-tag type="warning" v-else-if="'20' == scope.row.opStatus">{{ scope.row.opStatusName }}</el-tag>
									<el-tag type="info" v-else>{{ scope.row.opStatusName }}</el-tag>
								</div>
							</template>
						</el-table-column>

						<!-- <el-table-column prop="cusName" :label="$t('hytxs0000060')"></el-table-column> -->

						<el-table-column prop="bizTypeName" :label="$t('184333c81babf2f1')"></el-table-column>

						<el-table-column prop="opTypeName" :label="$t('i18nn_82b5bd967d494be3')"></el-table-column>

						
						
						<el-table-column prop="feeTypeName" :label="$t('46c3f6e0f657e7a3')"></el-table-column>
						
						<el-table-column prop="totalFeeAmt" :label="$t('d7315fb8114eb446')"></el-table-column>
						
						<el-table-column prop="opType" :label="'操作类型Code'"></el-table-column>
						
						<el-table-column prop="feeType" :label="'费用类型Code'"></el-table-column>

						<el-table-column prop="isBillName" :label="$t('i18nn_06dd7cb65641390b')">
							<template slot-scope="scope">
								<div>
									<el-tag v-if="'10' == scope.row.isBill">{{ scope.row.isBillName }}</el-tag>
									<el-tag type="warning" v-else-if="'20' == scope.row.isBill">{{ scope.row.isBillName }}</el-tag>
									<el-tag type="info" v-else>{{ scope.row.isBillName }}</el-tag>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="describe" :label="$t('i18nn_ab6ea90b9164b20a')" show-overflow-tooltip></el-table-column>

						<el-table-column prop="opTime" :label="$t('i18nn_d99d790ec4383bfb')"></el-table-column>
						<!-- <el-table-column :label="$t('93f5ca01b006206c')" width="180px" fixed="right">
							<template slot-scope="scope">
								<div>
									
								</div>
							</template>
						</el-table-column> -->
					</el-table>
				</div>
				<div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
			</div>
		</div>

		<!-- 详情弹窗 -->
		<!-- <el-dialog :title="$t('i18nn_6afbe4c6dcf87a6c')" :close-on-click-modal="false" :visible.sync="dialogDetVisible" :top="'10px'" :width="'800px'">
			<div v-loading="loading_det">
				<div class="tableCon" v-loading="loading_det" :element-loading-text="$t('47df8be257c59dde')">
					<div class="tableConTable">
						<el-table :height="$store.state.tableMaxHeight" border :data="detData" style="width: 100%">
							<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>

							<el-table-column prop="feeTypeName" min-width="100px" :label="$t('i18nn_a4eca6ff2588d791')"></el-table-column>
							<el-table-column prop="feeAmt" min-width="100px" :label="$t('d7315fb8114eb446')"></el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhBillPCPUPage"
			:expHttpFilter="pageFilterData()"
			:type="'bill'"
			:sheet2StaticData="sheet2StaticData"
		></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';

// import BillingRecords from '@/components/WarehouseCenter2/FinanceMana/BillingRecords.vue';

import {formatCalcData,formatCalcExcelData} from '@/components/WarehouseCenter2/FinanceMana/BillModule/caclBill.js';

export default {
	components: {
		// cusSelFuzzy,
		// whNoSelect,
		whExcelCustom
		// BillingRecords
	},
	props: {
		queryTime:{},
		cusUserId: {
			default: function() {
				return '';
			},
			type: String
		},
		whNo: {
			default: function() {
				return '';
			},
			type: String
		},
		daterange: {
			default: function() {
				return [];
			},
			type: Array
		},
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
			sheet2StaticData:[],
			
			// cusUserId: '',
			// cusUserName: '',
			//打开选择框
			// dialogSelVisible: false,

			// dialogAddVisible: false,
			// selBillData: {},
			// openRowData: {},
			// openRowIndex: {},

			// dialogFormVisible: false,
			// dialogFormVisibleMsg: '',
			// dialogFormInvalidDate: false,
			// currentSel: {},
			multipleSelection: [],

			// pickerOptions: {
			// 	// disabledDate(time) {
			// 	//   return time.getTime() >= Date.now();
			// 	// },
			// 	shortcuts: [
			// 		{
			// 			text: 'Latest Week',
			// 			onClick(picker) {
			// 				const end = new Date();
			// 				const start = new Date();
			// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
			// 				picker.$emit('pick', [start, end]);
			// 			}
			// 		},
			// 		{
			// 			text: 'Last Month',
			// 			onClick(picker) {
			// 				const end = new Date();
			// 				const start = new Date();
			// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			// 				picker.$emit('pick', [start, end]);
			// 			}
			// 		},
			// 		{
			// 			text: 'Last Three M',
			// 			onClick(picker) {
			// 				const end = new Date();
			// 				const start = new Date();
			// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
			// 				picker.$emit('pick', [start, end]);
			// 			}
			// 		}
			// 	]
			// },

			// ],
			selectOption: {
				wh_no: [],
				wh_op_status: [],
				wh_fin_bill_status: [],
				wh_op_type: [],
				// wh_op_is_bill:[],
				// wh_op_record_type:[],
				calcType: [
					{
						value: 'v',
						label: this.$t('i18nn_7f995eb43e1fc92b')
					},
					{
						value: 'w',
						label: this.$t('i18nn_02ad74c45e9271a0')
					}
				],
				plStatusList: [
					{
						value: '',
						label: this.$t('16853bda54120bf1')
					},
					{
						value: '1',
						label: this.$t('i18nn_9cee201b82dbc9cb')
					},
					{
						value: '2',
						label: this.$t('1ff1fd8ddb3e0e8d')
					}
				]
			},

			//表格数据
			//loading,表格数据
			loading: false,
			loading_load: false,
			//表格数据
			tableData: [],
			
			loading_count: false,
			countData:{},
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			//详情数据
			// loading_det: false,

			// detData: [],
			// dialogDetVisible: false,

			// form: {
			// 	id: null,

			// 	title: '', //标题',
			// 	describe: '', //this.$t('i18nn_ab6ea90b9164b20a'),
			// 	remark: '',
			// 	finRecords: [
			// 		{
			// 			finName: '', //this.$t('i18nn_a4eca6ff2588d791'),
			// 			finAmt: '' //"0",
			// 			// "id":"",//"数据ID"
			// 		}
			// 	]

			// },

			// formRules: {
			// 	title: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'blur' }]
			// },
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				// orderBy: 'id_', //排序字段
				// sortAsc: 'desc', //desc降序，asc升序
				calcType:'v',//合计需要的参数;v-体积，w-重量;默认体积
				daterange: [],
				
				// daterange: [],
				wh_op_status: '20',
				wh_op_is_bill: '',
				wh_fin_bill_status:'',
				wh_op_type:'',
				// wh_op_status: '',
				// plStatus:'',
				// isBill:'',
				// billStatus: '',
				whNo: '',
				relationNo: ''
				// plName: ''
			}
		};
	},
	watch: {
		queryTime: function(newVal, oldVal) {
			console.log('queryTime');
			this.initData();
		},
		// whNo: function(newVal, oldVal) {
		// 	console.log('whNo');
		// 	this.initData();
		// },
		// daterange: function(newVal, oldVal) {
		// 	console.log('daterange');
		// 	this.initData();
		// }
	},
	activated() {
		console.log('activated');
		// if (this.$route.query && this.$route.query.cusUserId) {
		// 	this.cusUserId = this.$route.query.cusUserId;
		// }
		// this.$nextTick(() => {
		// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
		// });
		// this.initData();
	},
	//创建时
	created() {
		// this.initData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		getDicData(['wh_op_status','wh_op_type','wh_fin_bill_status'],
			(data)=>{
				this.selectOption.wh_op_status = data['wh_op_status'];
				this.selectOption.wh_op_type = data['wh_op_type'];
				this.selectOption.wh_fin_bill_status = data['wh_fin_bill_status'];
		});
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.filterData.whNo = this.whNo;
			this.filterData.daterange = this.daterange;
			
			// if (!this.cusUserId) {
			// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	return;
			// }
			// if (!this.filterData.whNo) {
			// 	this.$message.warning(this.$t('i18nn_6ac98f81bd978520'));
			// 	return;
			// }
			// if (!this.filterData.daterange || this.filterData.daterange.length<=0) {
			// 	this.$message.warning(this.$t('i18nn_9c4659d873c8b417'));
			// 	return;
			// }
			let verify_msg = [];
			if (!this.cusUserId) {
				verify_msg.push(this.$t('i18nn_18d7892498938435'));
			}
			if (!this.filterData.whNo) {
				verify_msg.push(this.$t('c944a6686d996ab3'));
			}
			if (!this.filterData.daterange || this.filterData.daterange.length <= 0) {
				verify_msg.push(this.$t('43a3586339251494'));
			}
			if(verify_msg.length>0){
				this.$message.warning(this.$t('2ad108ab2c560530')+verify_msg.join(','));
				return;
			}
			this.getCountData();
			this.getPageData();
			// this.getDicData();
			this.$nextTick(() => {
			  this.$refs.multipleTable.doLayout(); //解决表格错位
			});
		},
		// changCus(data) {
		// 	console.log('changCus', data);
		// 	this.cusUserId = data.userId;
		// 	// this.cusUserName = data.companyName;
		// 	this.initData();
		// },
		// changWhNo(data) {
		// 	console.log('changWhNo', data);
		// 	this.filterData.whNo = data.code;
		// 	// this.filterData.userId = data.userId;
		// 	this.initData();
		// },
		//导出excel的列名和key
		getColumns(){
			let columns = [
				{
					title: this.$t('5acbec83efb27445'),
					key: 'whNo'
				},
				{
					title: this.$t('i18nn_9168144190f66f5d'),
					key: 'relationNo'
				},
				// {
				// 	title: this.$t('i18nn_45eae388d108d4fe'),
				// 	key: 'opStatusName'
				// },
				{
					title: this.$t('184333c81babf2f1'),
					key: 'bizTypeName'
				},
				{
					title: this.$t('i18nn_82b5bd967d494be3'),
					key: 'opTypeName',
				},
				// {
				// 	title: '操作类型Code',
				// 	key: 'opType'
				// },
				{
					title: this.$t('46c3f6e0f657e7a3'),
					key: 'feeTypeName',
				},
				
				{
					title: this.$t('d7315fb8114eb446'),
					key: 'totalFeeAmt'
				},
				// {
				// 	title: '费用类型Code',
				// 	key: 'feeType',
				// },
				// {
				// 	title: this.$t('i18nn_06dd7cb65641390b'),
				// 	key: 'isBillName',
				// },
				{
					title: this.$t('i18nn_ab6ea90b9164b20a'),
					key: 'describe',
				},
				{
					title: this.$t('i18nn_d99d790ec4383bfb'),
					key: 'opTime',
				},
			];
			//合并计算的导出
			let caclColumns = formatCalcExcelData('');
			columns = columns.concat(caclColumns);
			
			return columns;
		},
		//导出 excel
		exportExcel() {
			let columns = this.getColumns();
			
			// if (!this.cusUserId) {
			// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	return;
			// }
			// if (!this.filterData.whNo) {
			// 	this.$message.warning(this.$t('i18nn_6ac98f81bd978520'));
			// 	return;
			// }
			// if (!this.filterData.daterange || this.filterData.daterange.length<=0) {
			// 	this.$message.warning(this.$t('i18nn_9c4659d873c8b417'));
			// 	return;
			// }
			let verify_msg = [];
			if (!this.cusUserId) {
				verify_msg.push(this.$t('i18nn_18d7892498938435'));
			}
			if (!this.filterData.whNo) {
				verify_msg.push(this.$t('c944a6686d996ab3'));
			}
			if (!this.filterData.daterange || this.filterData.daterange.length <= 0) {
				verify_msg.push(this.$t('43a3586339251494'));
			}
			if(verify_msg.length>0){
				this.$message.warning(this.$t('2ad108ab2c560530')+verify_msg.join(','));
				return;
			}
			
			let Data = this.tableData;
		
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = '港口提柜_BillPCPU';
			this.excelOption = { height: 20 };
			this.excelOpenTime = new Date().getTime();
		
		},
		// changWhNo2(data) {
		// 	console.log('changWhNo', data);
		// 	this.form.whNo = data.code;
		// 	// this.filterData.userId = data.userId;
		// 	// this.initData();
		// },

		// changCus2(data) {
		// 	console.log('changCus', data);
		// 	this.form.cusName = data.companyName;
		// 	this.form.cusNo = data.cusNo;
		// },
		
		//格式化计算后的数据
		formatCalcData(data,type){
			return formatCalcData(data,type);
		},

		//分页的筛选项数据
		pageFilterData() {
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
				// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			}
			return {
				userId: this.cusUserId,
				// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
				whNo: this.filterData.whNo ? this.filterData.whNo : null,
				// workNo: this.filterData.workNo ? this.filterData.workNo : null,
				// wh_op_is_bill: this.filterData.wh_op_is_bill? this.filterData.wh_op_is_bill:null,
				isBill: this.filterData.wh_fin_bill_status ? this.filterData.wh_fin_bill_status : null,
				opStatus: this.filterData.wh_op_status ? this.filterData.wh_op_status : null,
				// opNo: this.filterData.opNo ? this.filterData.opNo : null,
				// isBill: this.filterData.wh_op_is_bill ? this.filterData.wh_op_is_bill : null,
				opTimeStart: startTime ? startTime : null,
				opTimeEnd: endTime ? endTime : null,
				opType:this.filterData.wh_op_type ? this.filterData.wh_op_type : null,
				relationNo: this.filterData.relationNo ? this.filterData.relationNo : null
				// bizType: this.filterData.bizType ? this.filterData.bizType : null,
				// "plName": this.filterData.plName? this.filterData.plName:null,
				// "goodsType": this.queryParamObj.goodsType
			};
		},
		//请求分页数据
		getPageData() {
			let filterData = Object.assign(
				{
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				},
				this.pageFilterData()
			);

			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhBillPCPUPage, filterData)
				.then(({ data }) => {
					this.loading_load = false;
					if (200 == data.code) {
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					} else {
						this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error(this.$t('hytxs0000030'));
					this.loading_load = false;
				});
		},
		//请求合计数据
		getCountData() {
			let filterData = Object.assign(
				{},
				this.pageFilterData()
			);
		
			this.loading_count = true;
			this.$http
				.put(this.$urlConfig.WhBillPCPUTotal, filterData)
				.then(({ data }) => {
					this.loading_count = false;
					if (200 == data.code) {
						//表格显示数据
						this.countData = data.data;
						// this.sheet2StaticData = [{
						// 	title: this.$t('i18nn_6e9c4e33131b18a7'),
						// 	data: this.countData.recordCount,
						// },{
						// 	title: this.$t('i18nn_da35456de5403862'),
						// 	data: this.countData.totalFeeAmt,
						// }]
						// this.sheet2StaticData = {
						// 	sheetName: "sheet2",
						// 	columns:[
						// 		{
						// 			title: this.$t('i18nn_6e9c4e33131b18a7'),
						// 			key: 'recordCount',
						// 		},
						// 		{
						// 			title: this.$t('i18nn_da35456de5403862'),
						// 			key: 'totalFeeAmt',
						// 		},
						// 	],
						// 	Data:[this.countData]
						// };
						this.sheet2StaticData = [
							[this.$t('i18nn_6e9c4e33131b18a7'),this.countData.recordCount],
							[this.$t('i18nn_da35456de5403862'),this.countData.totalFeeAmt]
						];
					} else {
						this.$message.warning(data.msg ? data.msg :  this.$t('i18nn_bc118f4faee1209f'));
					}
				})
				.catch(error => {
					console.log(error);
					this.$message.error( '合计查询失败!');
					this.loading_count = false;
				});
		},
		//打开详情弹窗
		// getDetListData(id) {
		// 	this.loading_det = true;
		// 	this.$http
		// 		.get(this.$urlConfig.WhFeeBillOpRecordDetList + '/' + id + '/fees', {})
		// 		.then(({ data }) => {
		// 			this.loading_det = false;
		// 			if (200 == data.code && data.data && data.data.feeList) {
		// 				//表格显示数据
		// 				this.detData = data.data.feeList;
		// 				// this.form.finRecords = data.rows;
		// 			} else {
		// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_9c0ace2b5d32f74c'));
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			this.$message.error('请求数据有误！');
		// 			this.loading_det = false;
		// 		});
		// },

		//选择行
		handleCurrentChange(row, event, column) {
			// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
		},
		//多选
		handleSelectionChange(val) {
			console.log(val);
			// this.multipleSelection = val;
		},
		//废弃
		// AbandonedAction(event, row) {
		// 	event.stopPropagation();
		// 	console.log('AbandonedAction', row);
		// 	let parm = [];

		// 	if (row) {
		// 		// 单条
		// 		parm = [row.id];
		// 	} else {
		// 		//多条
		// 		let dataList = this.multipleSelection;
		// 		if (dataList.length < 1) {
		// 			this.$message.warning(this.$t('7b80e66b535a3732'));
		// 			return;
		// 		}

		// 		let dataListParm = dataList.map(v => v.id);
		// 		parm = dataListParm;
		// 	}
		// 	this.$confirm('确定废弃吗?', this.$t('daaaeb1b7b22b126'), {
		// 		type: 'warning'
		// 	})
		// 		.then(() => {
		// 			this.postData(this.$urlConfig.WhFeeBillOpRecordAbandoned, parm, () => {
		// 				this.initData();
		// 				this.$message.success(this.$t('i18nn_f66fc986e2ae222f'));
		// 			});
		// 		})
		// 		.catch(() => {});
		// },

		//查看费用明细
		// showDetAction(event, row) {
		// 	event.stopPropagation();
		// 	console.log('showDetAction', row);
		// 	this.dialogDetVisible = true;
		// 	this.detData = [];
		// 	this.getDetListData(row.id);
		// },

		//重新计算
		// recalculateAction(event, row) {
		// 	event.stopPropagation();
		// 	console.log('completeAction', row);
		// 	let parm = [];

		// 	if (row) {
		// 		// 单条
		// 		parm = [row.id];
		// 	} else {
		// 		//多条
		// 		let dataList = this.multipleSelection;
		// 		if (dataList.length < 1) {
		// 			this.$message.warning(this.$t('7b80e66b535a3732'));
		// 			return;
		// 		}

		// 		let dataListParm = dataList.map(v => v.id);
		// 		parm = dataListParm;
		// 	}

		// 	this.postData(this.$urlConfig.WhFeeBillOpRecordClac, parm, () => {
		// 		this.initData();
		// 		this.$message.success(this.$t('9f30371831a98237'));
		// 	});
		// },

		//提交数据
		postData(url, formData, callback) {
			// let _this = this;
			this.loading = true;

			this.$http
				.put(url, formData)
				.then(({ data }) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						if (!data.msg) {
							data.msg = this.$t('dbe331ab679cd67f');
						}
						this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					}
				})
				.catch(error => {
					console.log(error);
					console.log(this.$t('i18nn_a7d2e953195a5588'));
					this.loading = false;
					this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
						type: 'warning'
					});
				});
		},

		//查询数据字典
		// getDicData() {
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_status','wh_op_type','wh_fin_bill_status'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				// this.selectOption.wh_op_is_bill = data.data['wh_op_is_bill'];
		// 				this.selectOption.wh_op_status = data.data['wh_op_status'];
		// 				this.selectOption.wh_op_type = data.data['wh_op_type'];
		// 				this.selectOption.wh_fin_bill_status = data.data['wh_fin_bill_status'];
		// 				// this.selectOption.wh_fin_bill_status = data.data['wh_fin_bill_status'];
		// 				// this.selectOption.wh_op_record_type = data.data['wh_op_record_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('hytxs0000032');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('hytxs0000033'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// .table-inline-text {
	// 	ul {
	// 		display: flex;
	// 		flex-wrap: wrap;
	
	// 		li {
	// 			margin-right: 20px;
	// 		}
	// 	}
	// }
</style>
